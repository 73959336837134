import React, { useState } from 'react';
import { useSetRecoilState } from 'recoil';
import { alertState } from '../app/recoil';
import { ListSearchAndSelect } from '../ui';
import { useUsersSearch } from './useUsersSearch';

export const UsersSearchAndSelect = ({
    title,
    subTitle,
    selected,
    setSelected,
    admin,
    instructor,
    disabled,
    searchInputSize,
    clientId,
    disableRemove,
    createdBy,
}) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [pageNumber, setPageNumber] = useState(1);

    const setAlert = useSetRecoilState(alertState);

    const { results, hasMore, loading, error } = useUsersSearch(
        searchQuery,
        pageNumber,
        5,
        admin,
        instructor,
        clientId
    );

    const getDisplayText = (item) => `${item.firstName} ${item.lastName}`;

    return (
        <ListSearchAndSelect
            heading={title}
            subHeading={subTitle}
            list={results}
            selectedList={selected}
            setSelectedList={setSelected}
            idField="_id"
            subTextField="email"
            paginationMode="server"
            search={searchQuery}
            setSearch={setSearchQuery}
            setPageNumber={setPageNumber}
            loading={loading}
            hasMore={hasMore}
            getDisplayText={getDisplayText}
            // onClickSelected={onClickSelected}
            selectedListView={'list'}
            disabled={disabled}
            disableRemove={disableRemove}
            searchInputSize={searchInputSize || 'normal'}
        />
    );
};
