import { Box, Container, Grid, Loading, Typography } from '../ui';
import { useUserNotifications } from './useUserNotifications';
import { useCurrentUser } from '../my-account/useCurrentUser';
import { NotificationItem } from './NotificationItem';
// import { NotificationBox } from './NotificationBox';
// import { AdminNotification } from './AdminNotification';

const NotificationsPage = ({ isAdminRoute, isInstructorRoute }) => {
    const { user, isLoading: loadingUser } = useCurrentUser();
    const {
        notifications,
        starweaverNotification,
        isLoading,
        deleteNotification,
        handleRead,
    } = useUserNotifications(user?._id, isAdminRoute, isInstructorRoute);

    return (
        <Box pt={2}>
            <Container maxWidth="xl">
                <Box mt={3}>
                    <Grid container spacing={2}>
                        {/* <Grid item xs={12} md={3}>
                            <NotificationBox />
                        </Grid> */}

                        <Grid item xs={12} md={12}>
                            <Box>
                                {isLoading && loadingUser ? (
                                    <Loading height="200px" />
                                ) : (
                                    <>
                                        {/* {starweaverNotification?._id && (
                                            <AdminNotification
                                                notification={
                                                    starweaverNotification
                                                }
                                            />
                                        )} */}
                                        {notifications.length ? (
                                            notifications.map(
                                                (notification, index) => (
                                                    <>
                                                        <NotificationItem
                                                            start={index === 0}
                                                            end={
                                                                index ===
                                                                notifications.length -
                                                                    1
                                                            }
                                                            handleRead={
                                                                handleRead
                                                            }
                                                            deleteNotification={
                                                                deleteNotification
                                                            }
                                                            notification={
                                                                notification
                                                            }
                                                        />
                                                    </>
                                                )
                                            )
                                        ) : (
                                            <Typography>
                                                There are no notifications
                                            </Typography>
                                        )}
                                    </>
                                )}
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};
export default NotificationsPage;
