import { Box, Step, Stepper /* StepLabel */ } from '..';
import StepButton from '@mui/material/StepButton';
import Check from '@mui/icons-material/Check';
import { makeStyles } from 'tss-react/mui';
// import StepConnector from '@mui/material/StepConnector';
import PropTypes from 'prop-types';
import { blue } from '@mui/material/colors';
import { StarsRounded } from '@mui/icons-material';

// const QontoConnector = withStyles({
//     alternativeLabel: {
//         top: 10,
//         left: 'calc(-50% + 16px)',
//         right: 'calc(50% + 16px)',
//     },
//     active: {
//         '& $line': {
//             borderColor: blue[600],
//         },
//     },
//     completed: {
//         '& $line': {
//             borderColor: blue[600],
//         },
//     },
//     line: {
//         borderColor: '#eaeaf0',
//         borderTopWidth: 3,
//         borderRadius: 1,
//     },
// })(StepConnector);

const useQontoStepIconStyles = makeStyles()((theme) => ({
    root: {
        color: '#eaeaf0',
        display: 'flex',
        height: 22,
        alignItems: 'center',
    },
    active: {
        color: blue[600],
    },
    circle: {
        width: 8,
        height: 8,
        borderRadius: '50%',
        backgroundColor: 'currentColor',
    },
    completed: {
        color: blue[600],
        zIndex: 1,
        fontSize: 18,
    },
}));

function QontoStepIcon(props) {
    const classes = useQontoStepIconStyles();
    const { active, completed } = props;

    return (
        <div className={`${classes.root} ${active ? classes.active : ''}`}>
            {completed ? (
                <Check className={classes.completed} />
            ) : (
                <div className={classes.circle} />
            )}
        </div>
    );
}

QontoStepIcon.propTypes = {
    /**
     * Whether this step is active.
     */
    active: PropTypes.bool,
    /**
     * Mark the step as completed. Is passed to child components.
     */
    completed: PropTypes.bool,
};

export const StepperProgressBar = ({
    step = 0,
    steps,
    setTabValue = undefined,
    style,
}) => {
    const handleStepClick = (idx) => {
        if (setTabValue !== undefined) {
            setTabValue(idx);
        }
    };

    return (
        <Box mt={2}>
            <Stepper
                alternativeLabel
                nonLinear
                activeStep={step}
                style={{
                    backgroundColor: '#ffff',
                    ...(style ? style : {}),
                }}
                // connector={<QontoConnector />}
            >
                {steps.map((label, idx) => (
                    <Step
                        key={typeof label === 'string' ? label : label?.name}
                        style={{
                            display: label?.hideStepper ? 'none' : 'initial',
                        }}
                    >
                        {/* <StepLabel StepIconComponent={QontoStepIcon}>
                            {label}
                        </StepLabel> */}
                        <StepButton
                            onClick={() => handleStepClick(idx)}
                            icon={
                                label?.icon || (
                                    <StarsRounded
                                        color={
                                            idx <= step
                                                ? 'secondary'
                                                : 'disabled'
                                        }
                                    />
                                )
                            }
                        >
                            <small>
                                {typeof label === 'string'
                                    ? label
                                    : label?.name}
                            </small>
                        </StepButton>
                    </Step>
                ))}
            </Stepper>
        </Box>
    );
};
