import { makeStyles } from 'tss-react/mui';
import image from '../img/curvedBg.svg';
import { blue } from '@mui/material/colors';

export const useStyles = makeStyles()((theme) => ({
    heading: {
        fontSize: '2.5rem',
        fontWeight: '700',
        textAlign: 'center',
    },

    subHeading: {
        fontSize: '1.5em',
        textAlign: 'center',
        margin: '10px 0',
    },
    // BANNER
    banner: {
        position: 'relative',
        marginBottom: 140,
        overflow: 'visible',
        zIndex: '200',
        marginTop: '-20px',
        padding: '50px 0 20px',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: '-3%',
            left: '-19%',
            width: '125vw',
            height: '150%',
            zIndex: '-300',
            backgroundPosition: 'bottom',
            backgroundImage: `url(${image})`,
            backgroundSize: 'cover',
        },
        [theme.breakpoints.down('md')]: {
            '&::before': {
                top: '-23%',
            },
        },
        [theme.breakpoints.down('xl')]: {
            '&::before': {
                top: '-30%',
                height: '170%',
            },
        },
    },
    visitBtn: {
        backgroundColor: blue[600],
        width: '100px',
        '&:hover': {
            backgroundColor: blue[800],
        },
    },
    rightRect: {
        position: 'absolute',
        top: '-10%',
        right: '-8vw',
        height: '30vw',
        width: '30vw',
        borderRadius: '50%',
        zIndex: '-200',
        backgroundColor: '#fafafa',
        [theme.breakpoints.down('md')]: {
            display: 'none',
        },
    },
    info: {
        color: '#fafafa',
        margin: ' 20px 0 0 50px',
    },
    instructorName: {
        position: 'relative',
        color: '#fafafa',
        display: 'grid',
        margin: '10px 0',
        placeItems: 'center',
        width: 'fit-content',
        '&::before': {
            position: 'absolute',
            content: '""',
            top: '-6%',
            left: '-30vw',
            clipPath: 'polygon(10% 0%, 100% 0%, 90% 100%, 0% 100%)',
            width: '60vw',
            height: '100%',
            backgroundColor: '#333',
            zIndex: '-10',
        },
        [theme.breakpoints.down('md')]: {
            '&::before': {
                width: '110vw',
                left: '-40vw',
            },
        },
    },
    img: {
        position: 'relative',
        height: '300px',
        width: '350px',
        boxShadow: '0px 5px 10px 1px rgba(0,0,0,0.4)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginBottom: '10px',
    },
    // TOP PICKS COURSE COMPONENT
    link: {
        display: 'flex',
        flexFlow: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        float: 'right',
        cursor: 'pointer',
        color: '#333',
        margin: '-20px 0 0 0',
    },
    topPicksCourse: {
        position: 'relative',
        // overflow: 'hidden',
        '&:nth-child(even)': {
            marginTop: 25,
        },
    },
    picksBg: {
        position: 'absolute',
        background:
            'linear-gradient(90deg, rgba(234,113,18,0.7175245098039216) 0%, rgba(234,113,18,0.896796218487395) 54%, rgba(234,113,18,0.4766281512605042) 74%)',
        height: '105%',
        width: '85%',
        zIndex: '-10',
        clipPath: 'polygon(15% 1%, 100% 0%, 85% 100%, 0% 100%)',
        [theme.breakpoints.down('md')]: {
            clipPath: 'none',
            width: '200%',
        },
    },
    picksBgCourses: {
        top: 0,
        left: '-20%',
    },
    picksBgOnAir: {
        background: '#e3e3e3',
        right: '-20%',
    },
    sectionHeading: {
        fontSize: 'min(2em,7vw)',
        color: '#fafafa',
        fontWeight: 400,
        cursor: 'default',
    },
    cardsConatiner: {
        position: 'relative',
        margin: '40px 0',
        cursor: 'pointer',
    },
    card: {
        display: 'flex',
        flexFlow: 'column',
        maxWidth: '350px',
        minHeight: '500px',
        padding: '10px 20px',
        margin: '0 auto',
        cursor: 'pointer',
        borderRadius: 5,
        backgroundColor: '#fafafa',
        transition: '0.2s all ease-out',
        border: '1px solid rgba(0,0,0,0)',
        position: 'relative',
        '&:hover': {
            transform: 'scale(1.05)',
            border: '1px solid rgba(0,0,0,0.1)',
            '& $cardImg': {
                boxShadow: '0px 8px 5px 1px rgba(0,0,0,0.4)',
                transform: 'rotate(-2deg)',
            },
            '& $cardHeading': {
                whiteSpace: 'normal',
            },
            '& $cardBackdrop': {
                '&::before': {
                    backgroundColor: 'rgba(0,0,0,0.4)',
                },
            },
        },
    },
    cardActions: {
        display: 'flex',
        flexFlow: 'row',
        marginTop: '20px',
        textAlign: 'center',
        display: 'block',
        paddingBottom: 20,
    },
    cardBtn: {
        marginRight: '20px',
        width: '100%',
        marginRight: 10,
        paddingBottom: 5,
    },
    cardImg: {
        width: '100%',
        borderRadius: 5,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        transition: '0.2s all ease-out',

        boxShadow: '0px 0px 10px 1px rgba(0,0,0,0)',
        overflow: 'hidden',
    },
    // dashboard tabs
    centerTabs: {
        display: 'flex',
        justifyContent: 'center',
    },
    // dashboard tabs
    // USEING IN ONAIR CARD
    cardBackdrop: {
        position: 'relative',
        borderRadius: 5,
        '&:before': {
            position: 'absolute',
            content: '""',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1,
            transition: 'all 0.2s ease-in',
            backgroundColor: 'rgba(0,0,0,0)',
        },
    },
    changeside: {
        color: '#333',
    },
    // TIME IN ONAIR
    time: {
        fontSize: '1em',
        fontWeight: 500,
        color: '#858585',
        opacity: '1',
        padding: 0,
    },
    date: {
        marginRight: 10,
        fontSize: '1em',
    },
    infoContainer: {
        display: 'flex',
        flexFlow: 'row',
        // justifyContent: 'center',
        alignItems: 'center',
    },
    cardHeading: {
        margin: '20px 0 5px',
        fontSize: 'min(1.3em, 5vw)',
        fontWeight: 'bold',
        padding: '0 10px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    instructor: {
        width: 'fit-content',
        margin: '10px 0',
    },
    controlBtn: {
        position: 'absolute',
        top: '50%',
        color: '#333',
    },
    right: {
        right: '-30px',
    },
    left: {
        left: '-20px',
    },
    sub: {
        margin: '10px 0',
    },
    load: {
        height: '450px',
        display: 'grid',
        placeItems: 'center',
    },

    // RECOMMENDATIONS
    introRecommendation: {
        marginTop: 50,
        minHeight: 500,
    },
    headingLink: {
        cursor: 'pointer',
        '&:hover': {
            color: blue[800],
        },
    },
    skipButton: {
        backgroundColor: blue[800],
        color: '#fafafa',
        '&:hover': {
            backgroundColor: blue[900],
        },
    },
    skipBtnContainer: {
        marginLeft: 0,
        marginTop: 15,
    },
    continueBtn: {
        marginTop: 15,
        marginBottom: 25,
    },
    mainBoxWrap: {
        position: 'relative',
        minHeight: 300,
        [theme.breakpoints.down('lg')]: {
            height: 'auto',
            minHeight: 'auto',
        },
    },
    mainBox: {
        width: '93%',
        borderRadius: '8px',
        marginLeft: '20px',
        marginRight: '10px',
        backgroundColor: '#ffffff',
        boxShadow: '0px 0px 13px 2px #AAA8A8',
        marginTop: '10px',
        position: 'relative',
        '&:hover': {
            position: 'absolute',
            zIndex: 1000,
            transition: 'all 0.3s ease-out',
            transform: 'scale(1.05)',
            '& $desc': {
                visibility: 'visible',
                display: 'block',
                backgroundColor: '#ffffff',
            },
            '& $cardHeading': {
                whiteSpace: 'normal',
            },
        },
    },
    desc: {
        display: 'none',
        padding: 8,
        marginTop: 0,
        fontSize: '16px',
    },
    root: {
        backgroundColor: '#ddd',
        // backgroundPosition: 'center',
        backgroundSize: '100% 100%',
        // backgroundRepeat: 'no-repeat',
        borderRadius: '6px 6px 0 0px',
        minHeight: '175px',
        width: '100%',
    },
    contentBox: {
        width: '100%',
        margin: '0 auto',
        padding: '3px 10px',
        transition: 'all 0.3s ease-in',
    },

    bannerimg: {
        marginTop: '-10px',
        background:
            'linear-gradient(90deg, rgba(234,113,18,1) 0%, rgba(234,113,18,0.6222864145658263) 35%, rgba(234,113,18,0.165703781512605) 74%)',
    },

    browseLink: {
        width: 115,
        display: 'block',
        margin: 'auto',
    },

    searchSortContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'center',
        alignItems: 'center',
        //width:"100%",
        width: 810,

        margin: 'auto',
        marginBottom: 50,
        marginTop: 20,

        [theme.breakpoints.down('md')]: {
            width: '90%',
        },
    },

    searchContainer: {
        flexGrow: 1,
    },

    sort: {
        marginLeft: 50,
        [theme.breakpoints.down('md')]: {
            marginLeft: 3,
        },
    },

    gridContainer: {
        marginTop: theme.spacing(2),
        display: 'grid',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '1rem',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(3, 1fr)',
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(4, 1fr)',
        },
    },
    dashboardGridContainer: {
        marginTop: theme.spacing(2),
        display: 'grid',
        width: '100%',
        position: 'relative',
        [theme.breakpoints.up('sm')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
            gap: '1rem',
        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },

    cardContainer: {
        height: '100%',
        boxShadow:
            '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
        borderRadius: '0.5rem',
        overflow: 'hidden',
        cursor: 'pointer',
        display: 'flex',
        flexDirection: 'column',
        transition: 'all 150ms linear',
        '&:hover': {
            transform: 'translateY(-10px)',
        },
    },
    imgContainer: {
        height: '12.5rem',
        width: '100%',
        '& > img': {
            height: '100%',
            width: '100%',
            objectFit: 'cover',
        },
    },
    cardContentWrapper: {
        padding: theme.spacing(0, 1.5),
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    cardDescription: {
        position: 'relative',
        overflow: 'hidden',
        height: '5rem',
        border: '2px solid red',
        '&::after': {
            content: '""',
            position: 'absolute',
            border: '2px solid green',
            left: 0,
            bottom: 0,
            width: '100%',
            height: '2em',
            zIndex: '1',
            pointerEvents: 'none',
            backgroundImage:
                'linear-gradient(to bottom, rgba(250,250,250, 0), rgba(250,250,250, 1) 90%)',
        },
    },
    StyledDescription: {
        height: '5rem',
        overflow: 'hidden',
        position: 'relative',
        '&::after': {
            content: '""',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            left: '0',
            height: '2.5rem',
            backgroundImage:
                'linear-gradient(to bottom, rgba(250, 250, 250, 0), rgba(250, 250, 250, 0.9) 90%)',
            zIndex: '1',
            pointerEvents: 'none',
        },
    },
    instructorSlug: {
        '&:hover': {
            textDecoration: 'underline',
        },
    },
    cardActions: {
        marginTop: 'auto',
    },

    cardRoot: {
        width: '100%',
        marginTop: '1rem',
        height: 5,
    },

    gridContainer2: {
        display: 'grid',
        gap: '1rem',
        [theme.breakpoints.up('md')]: {
            gridTemplateColumns: 'repeat(2, 1fr)',
        },
    },
}));
