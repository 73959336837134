import { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Fuse from 'fuse.js';
import {
    Box,
    CircularProgress,
    FormControl,
    Grid,
    Pagination,
    InputLabel,
    Typography,
    Button,
    SearchBox,
    Select,
} from '../ui';
import { FormatListBulletedIcon } from '../icons';
import { useStyles } from './styles';
import axios from 'axios';
import { LinearProgress } from '@mui/material';
import ReactHtmlParser from 'react-html-parser';
import InstructorDetails from './components/InstructorDetails';
import compareEnrolledCourses from './compareEnrolledCourses';

const MAX_COURSES_PER_PAGE = 12;

const ENROLLED_COURSES_SORTBY_OPTIONS = [
    { label: 'Recently Accessed', value: 'RECENT' },
    { label: 'Title', value: 'TITLE' },
    { label: '% Completed', value: 'PERCENTAGE_COMPLETED' },
    { label: 'Instructor', value: 'INSTRUCTOR' },
];

const MyContentCourseCard = ({ course, handleLaunch }) => {
    const { classes } = useStyles();
    let navigate = useNavigate();

    const percentage = useMemo(() => {
        return Math.round(course.percentageCompleted * 100 || 0);
    }, [course.percentageCompleted]);

    return (
        <article
            className={classes.cardContainer}
            onClick={(e) => {
                e.stopPropagation();
                navigate(`/courses/${course.slug}`);
            }}
        >
            <div className={classes.imgContainer}>
                <img
                    src={course.thumbnail}
                    height={200}
                    width={300}
                    alt={course.title}
                />
            </div>
            <div className={classes.cardContentWrapper}>
                <h2 className={classes.cardTitle}>
                    {ReactHtmlParser(course.title.replace('(Thinkific)', ''))}
                </h2>
                <Box color="#4e4e4e" fontWeight={600}>
                    <span className={classes.instructorName}>
                        {course.hours > 0 &&
                            `${course.hours} hour${
                                course.hours !== 1 ? 's' : ''
                            } `}
                        {course.minutes > 0 &&
                            `${course.minutes} minute${
                                course.minutes !== 1 ? 's' : ''
                            }`}
                    </span>
                </Box>
                <p className={classes.StyledDescription}>
                    {course.shortDescription || course.description}
                </p>
                <div className={classes.cardActions}>
                    <InstructorDetails instructors={course.instructors} />
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={(e) => {
                            handleLaunch(course);
                            e.stopPropagation();
                        }}
                    >
                        Go To Course
                    </Button>
                </div>
            </div>
            <Box className={classes.cardProgressWrapper} px={1.25}>
                <div className={classes.cardRoot}>
                    <LinearProgress
                        color="secondary"
                        variant="determinate"
                        value={percentage}
                        style={{ height: '100%', borderRadius: '100vmax' }}
                    />
                </div>
                <p className={classes.completePercentage}>
                    <span style={{ fontWeight: '500' }}>{percentage}%</span>{' '}
                    Completed
                </p>
            </Box>
        </article>
    );
};

export const SavedOnDemand = ({ user, savedOnDemand }) => {
    const { classes } = useStyles();
    const [page, setPage] = useState(1);
    const [filteredCourses, setFilteredCourses] = useState(savedOnDemand);
    const [paginatedCourses, setPaginatedCourses] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [sortBy, setSortBy] = useState('');

    useEffect(() => {
        if (savedOnDemand) {
            setFilteredCourses(savedOnDemand);
        }
    }, [savedOnDemand]);

    const totalPages = useMemo(() => {
        return Math.ceil((filteredCourses?.length || 0) / MAX_COURSES_PER_PAGE);
    }, [filteredCourses]);

    useEffect(() => {
        const paginateCourse = (pageNumber) => {
            if (filteredCourses?.length) {
                const start = (pageNumber - 1) * MAX_COURSES_PER_PAGE;
                const end = pageNumber * MAX_COURSES_PER_PAGE;
                setPaginatedCourses(filteredCourses.slice(start, end));
            } else {
                setPaginatedCourses([]);
            }
        };

        const timeoutValue = setTimeout(() => {
            paginateCourse(page);
        }, 50);

        return () => clearTimeout(timeoutValue);
    }, [page, filteredCourses]);

    useEffect(() => {
        const fuse = new Fuse(savedOnDemand, {
            keys: ['title', 'description', 'shortDescription'],
            includeScore: true,
        });
        const searchFunc = (query) => {
            return query !== ''
                ? fuse
                      .search(query)
                      .filter(({ score }) => score < 0.5)
                      .map(({ item }) => item)
                : savedOnDemand;
        };
        setFilteredCourses(searchFunc(searchQuery));
    }, [savedOnDemand, searchQuery]);

    const handleSearchQueryChange = (e) => {
        setSearchQuery(e.target.value);
        setPage(1);
    };

    const clearSearch = () => {
        setSearchQuery('');
        setPage(1);
        setFilteredCourses(savedOnDemand);
    };

    const handleSortByOptionChange = (event) => {
        setSortBy(event.target.value);
        setPage(1);
        setFilteredCourses((prevCourses) =>
            [...prevCourses].sort(compareEnrolledCourses(event.target.value))
        );
    };

    const handlePageChange = (event, value) => {
        window.scrollTo(0, 100);
        setPage(value);
    };

    const handleLaunch = async (course) => {
        try {
            await axios.post(`/api/users/thinkific/enroll`, {
                courseId: course.thinkificCourseId,
                userId: user.user_thinkific_id,
            });
            const response = await axios.get(
                `/api/sso/${course.slug}/thinkific?clientId=${user.client_id}`,
                {
                    firstName: user.user_firstName,
                    lastName: user.user_lastName,
                }
            );
            const ssoUrl = response.data;
            window.open(ssoUrl, '_blank');
        } catch (e) {
            console.error(e);
        }
    };

    return (
        <Box>
            {user ? (
                savedOnDemand.length > 0 ? (
                    <>
                        <Box
                            className={classes.searchSortContainer}
                            style={{ width: '100%' }}
                        >
                            <Box className={classes.searchContainer}>
                                <SearchBox
                                    searchQuery={searchQuery}
                                    placeHolder="Search my courses"
                                    handleSearchQueryChange={
                                        handleSearchQueryChange
                                    }
                                    clearSearch={clearSearch}
                                />
                            </Box>
                            <FormControl
                                variant="outlined"
                                color="secondary"
                                className={classes.sort}
                            >
                                <InputLabel
                                    color="secondary"
                                    style={{ backgroundColor: 'white' }}
                                >
                                    Sort by
                                </InputLabel>
                                <Select
                                    native
                                    value={sortBy}
                                    onChange={handleSortByOptionChange}
                                    color="secondary"
                                >
                                    <option value="" />
                                    {ENROLLED_COURSES_SORTBY_OPTIONS.map(
                                        ({ label, value }) => (
                                            <option key={value} value={value}>
                                                {label}
                                            </option>
                                        )
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                        <Grid container spacing={2}>
                            {paginatedCourses.map((course, idx) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    lg={3}
                                    key={`my-courses-content-${idx}`}
                                >
                                    <MyContentCourseCard
                                        course={course}
                                        handleLaunch={handleLaunch}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        {paginatedCourses.length > 0 && totalPages > 1 && (
                            <Grid xs={12}>
                                <Box mt={2} width="fit-content" mx="auto">
                                    <Pagination
                                        variant="outlined"
                                        color="secondary"
                                        count={totalPages}
                                        page={page}
                                        onChange={handlePageChange}
                                    />
                                </Box>
                            </Grid>
                        )}
                    </>
                ) : (
                    <Grid
                        container
                        alignItems="center"
                        style={{ minHeight: '300px' }}
                    >
                        <Grid item xs={12}>
                            <Typography
                                variant="h6"
                                gutterBottom
                                style={{ textAlign: 'center' }}
                            >
                                You don't have any saved courses
                            </Typography>
                            <Link to="/browse" className={classes.browseLink}>
                                <Button
                                    variant="contained"
                                    startIcon={<FormatListBulletedIcon />}
                                >
                                    Browse
                                </Button>
                            </Link>
                        </Grid>
                    </Grid>
                )
            ) : (
                <Box className={classes.load}>
                    <CircularProgress color="secondary" />
                </Box>
            )}
        </Box>
    );
};
