function getKeywords(selectedTopics = [], selectedCustomTopics = []) {
    let keywords = [];

    if (Array.isArray(selectedTopics)) {
        for (let i = 0; i < selectedTopics.length; i++) {
            const keyword = selectedTopics[i].name;
            keywords.push(keyword);
        }
    }

    if (Array.isArray(selectedCustomTopics)) {
        for (let i = 0; i < selectedCustomTopics.length; i++) {
            const keyword = selectedCustomTopics[i];
            keywords.push(keyword);
        }
    }

    return keywords;
}

export { getKeywords };
